import React from 'react';
import Home from './components/home/Home';
import Services from './components/services/Services';
import Us from './components/us/Us';
import Navbar from './components/navbar';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import ContactUs from './components/contactUs/ContactUs';
import { ReactSEOMetaTags } from 'react-seo-meta-tags'

function App() {

  React.useEffect(() => {
    document.title = "Empresa de Transportes Armadillo S.A.C."
  }, []);

  const ROUTES =
    <Switch>
      <Route exact path="/">
        <Home />
      </Route>
      <Route exact path="/home">
        <Home />
      </Route>
      <Route exact path="/services">
        <Services />
      </Route>
      <Route exact path="/aboutUs">
        <Us />
      </Route>
      <Route exact path="/contactUs">
        <ContactUs />
      </Route>
    </Switch>

  const siteMetadata = {
    url: 'https://etarmadillo.com',
    title: 'Empresa de Transportes Armadillo S.A.C.',
    datePublished: '2022-04-06T12:00:00.000Z',
    description: 'Especialistas en transporte seguro de valorados, apoyados en la vanguardia de estándares logísticos y tecnológicos.',
    language: 'es-ES',
    image: 'http://etarmadillo.com/logo400.png',
    author: {
      email: 'ilopez@etarmadillo.com',
      name: 'Iván López'
    },
    site: {
      siteName: 'Empresa de Transportes Armadillo S.A.C.',
    }
  }
  return (
    <BrowserRouter>
      <ReactSEOMetaTags
        website={{ ...siteMetadata }}
        breadcrumb={[
          { name: 'Empresa de Transportes Armadillo S.A.C.', url: 'https://etarmadillo.com' },
          { name: 'Mundo Armadillo', url: 'https://mundo.etarmadillo.com' },
        ]}
        organization={{
          name: 'ET Armadillo',
          legalName: 'Empresa de Transportes Armadillo S.A.C.',
          url: 'https://etarmadillo.com',
          logo: 'http://etarmadillo.com/logo400.png'
        }}
      />
      <Navbar routes={ROUTES} />
    </BrowserRouter>

  );
}

export default App;
