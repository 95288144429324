import BannerAnim, { Element } from 'rc-banner-anim';
import TweenOne from 'rc-tween-one';
import 'rc-banner-anim/assets/index.css';
import './banner.css';
import slide01 from '../../assets/banner1-07.jpg';
import slide02 from '../../assets/banner2-08.jpg';
import slide03 from '../../assets/banner3-09.jpg';
const BgElement = Element.BgElement;

export default function Banner() {

    return(
        <BannerAnim prefixCls="banner-user" autoPlay>
            <Element 
                prefixCls="banner-user-elem"
                key="0"
            >
                <BgElement
                    key="bg"
                    className="bg"
                    style={{
                        backgroundImage: `url(${slide01})`,
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                    }}
                />
            </Element>
            <Element 
                prefixCls="banner-user-elem"
                key="1"
            >
                <BgElement
                    key="bg"
                    className="bg"
                    style={{
                        backgroundImage: `url(${slide02})`,
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                    }}
                />
            </Element>
            <Element 
                prefixCls="banner-user-elem"
                key="2"
            >
                <BgElement
                    key="bg"
                    className="bg"
                    style={{
                        backgroundImage: `url(${slide03})`,
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                    }}
                />
            </Element>
      </BannerAnim>)
}