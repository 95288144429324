import React from "react";
import './us.css';
import 'antd/dist/antd.css';
import '../../style/main.css';
import { Row, Col } from 'antd';
import line from '../../assets/us/slider-line.jpg';
import light from '../../assets/us/Innovacion.png';
import arrow from '../../assets/us/Compromiso.png';
import gear from '../../assets/us/Eficiencia.png';
import EquipoArmadillo from '../../assets/us/BannerSuperior.jpg';
import videoMali from '../../assets/us/ModeloTradicionalVSArmadillo.mp4';
import EnzoYOperador from '../../assets/us/EnzoYOperador.jpg';

export default function Us() {

    return(
        <React.Fragment>
            <div className='divBanner'>
                <img src={EquipoArmadillo} alt='Combinación'/>
            </div>
            <div className='divUs'>
                <Row>
                    <Col span={24} xs={24} sm={24} md={24} lg={24} xl={24} >
                        <div className="aboutETA">
                            <img src={line} alt='line'/>
                            <h2>
                                ACERCA DE ARMADILLO SAC
                            </h2>
                            <p>
                                Los más 20 años de experiencia en el sector logístico, retail y de negocios en el Perú nos dan el entendimiento para ofrecer soluciones efectivas y operativamente eficientes.
                            </p>
                        </div>
                        <br/>
                        <div className='videoDivModelo'>
                            <video className="videoModelo" controls >
                                <source src={videoMali} type="video/mp4"/>
                            </video>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col span={24} xs={24} sm={24} md={24} lg={24} xl={24} >
                        <div className="aboutETA">
                            <img src={line} alt='line'/>
                            <p>
                            En Armadillo estamos cambiando la manera de pensar en seguridad en el transporte de mercaderías. Permitiendo a nuestros clientes mejorar su competitividad.
                            </p>
                            
                        </div>
                        <br/>
                    </Col>
                </Row>
                <Row>
                    <Col span={12} xs={24} sm={24} md={24} lg={12} xl={12} >
                        <div className="divEnzoYOperador">
                            <img src={EnzoYOperador} alt='EnzoYOperador'/>
                        </div>
                        <br/>
                    </Col>
                    <Col span={12} xs={24} sm={24} md={24} lg={12} xl={12}>
                        <div className="divCompromise">
                            <img src={line} alt='line'/>
                            <h2>
                                NUESTRO COMPROMISO
                            </h2>
                            <ul>
                                    <li>Con la seguridad en nuestro país</li>
                                    <li>Con la integridad de nuestro personal y de cuidar la vida de cada persona</li>
                                    <li>A la satisfacción y cumplimiento con nuestro clientes</li>
                                    <li>A que nuestros clientes sean más eficientes</li>
                                </ul>
                            <br/>
                        </div> 
                    </Col>
                </Row>
                <div className="divPillars">
                    <h2>
                        NUESTROS PILARES
                    </h2>
                    <p>
                        SERVICIO DE TRANSPORTE DE MERCADERÍAS DE VALOR
                    </p>
                    <img src={line} alt='line'/>
                </div>
                <div className="ourPillars">
                    <Row>
                        <Col className="ourPillarsCol" span={8} xs={24} sm={24} md={12} lg={8} xl={8} >
                            <div>
                                <img src={light} alt='hands'/>
                            </div>
                            <h4>Innovación</h4>
                        </Col>
                        <Col className="ourPillarsCol" span={8} xs={24} sm={24} md={12} lg={8} xl={8}>
                            <div>
                                <img src={arrow} alt='hands'/>
                            </div>
                            <h4>Compromiso</h4>
                        </Col>
                        <Col className="ourPillarsCol" span={8} xs={24} sm={24} md={24} lg={8} xl={8}>
                            <div>
                                <img src={gear} alt='hands'/>
                            </div>
                            <h4>Eficiencia</h4>
                        </Col>
                    </Row>
                </div>
                
            </div>
        </React.Fragment>
    );
}