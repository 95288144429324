import  { useState} from 'react';
import { Layout} from 'antd';
import logo from '../../assets/navbar/logo.png';
import './navbar.css';
import 'antd/dist/antd.css';
import '../../style/main.css';
import Navigation from './Navigation';
import MobileNavigation from './MobileNavigation';
import NavButtons from './NavButtons';
import footer from '../../assets/navbar/footer-bkg.png';
import whatsapp from '../../assets/navbar/whatsapp.svg';

const { Header, Content, Footer } = Layout;

export default function Navbar(props){

    const [open, setOpen] = useState(false);

    return(
        <Layout >
            <Header className='headerNavbar'>
                    <div className="logoArmadillo">
                        <a href='localhost:3000'>
                            <img id="logo" src={logo} alt="logo" width={330}></img>
                        </a>
                    </div>
                    <Navigation navlinks={<NavButtons modo={"horizontal"} />} />
                    <MobileNavigation navlinks={<NavButtons modo={"inline"} theme={"dark"}/>} open={open} setOpen={setOpen}/>
            </Header>
            <Content style={{backgroung:'#ffffff'}}>
                {props.routes}
            </Content>
            <Footer className='footerAnt'>
                <div className='footerNav' >
                    <img src={footer} alt='footer'/>
                </div>
                <div className='whatsapp' >
                    <a href='https://api.whatsapp.com/send?phone=51990919919&text=Hola%20quisiera%20conversar%20con%20ustedes%20sobre%20su%20servicio' target="_blank" rel="noreferrer">
                        <img src={whatsapp} alt='whatsapp'/>
                    </a>
                    
                </div>
                <div className='copyRight' >
                    <p>Armadillo SAC 2022. Derechos Reservados.</p>
                </div>
            </Footer>
        </Layout>
    );
}