import React from 'react';
import 'rc-texty/assets/index.css';
import 'antd/dist/antd.css';
import '../../style/main.css';
import { Row, Col} from 'antd';
import './home.css';
import line from '../../assets/home/slider-line.jpg';
import item1 from '../../assets/home/MinimizamosRiesgos.png';
import item2 from '../../assets/home/MinimizamosPerdidas.png';
import item3 from '../../assets/home/ReducimosGastosOperativos.png';
import item4 from '../../assets/home/ProtegemosActivos.png';
import phone from '../../assets/home/ElectronicosYTecnologicos.png';
import antenna from '../../assets/home/EquiposDeComunicacion.png';
import play from '../../assets/home/ComputoVideojuegos.png';
import medicCase from '../../assets/home/ProductosMedicos.png';
import quimestry from '../../assets/home/QuimicosyProductosIndustriales.png';
import Banner from './Banner';

export default function Home() {

    return(
        <React.Fragment>
            <Banner/>
            
            <div className='message1'>
                <h2>ENFOCADO EN NUESTROS CLIENTES</h2>
                <p>TRANSPORTE ESPECIALIZADO A TU MEDIDA</p>
                <img src={line} alt='line'/>
            </div>
            <div className='features'>
                <Row>
                    <Col span={12} xs={24} sm={24} md={12} lg={12} xl={12} >
                        <section className='featuresHome'>
                            <div className="card">
                                <img src={item1} alt='item1'/>
                                <h3>Minimizamos Riesgos</h3>
                                <p>Con nuestros equipos, tecnología y protocolos especializados en el transporte de mercaderías valiosas.</p>
                            </div>
                        </section>
                    </Col>
                    <Col span={12} xs={24} sm={24} md={12} lg={12} xl={12}>
                        <section className='featuresHome'>
                            <div className="card">
                                <img src={item2} alt='item2'/>
                                <h3>Minimizamos pérdidas</h3>
                                <p>Reducimos la probabilidad y efectividad de asaltos urbanos.</p>
                            </div>
                        </section>
                    </Col>
                </Row>
                <Row>
                    <Col span={12} xs={24} sm={24} md={12} lg={12} xl={12}>
                        <section className='featuresHome'>
                            <div className="card">
                                <img src={item3} alt='item1'/>
                                <h3>Reducimos Gastos Operativos</h3>
                                <p>Tenemos la capacidad y seguridad de transportar mayor cantidad de mercaderías en menos viajes.</p>
                            </div>
                        </section>
                    </Col>
                    <Col span={12} xs={24} sm={24} md={12} lg={12} xl={12}>
                        <section className='featuresHome'>
                            <div className="card">
                                <img src={item4} alt='item2'/>
                                <h3>Protegemos activos</h3>
                                <p>El 40% de robo de mercaderías ocurren durante los traslados.</p>
                            </div>
                        </section>
                    </Col>
                </Row>
            </div>
            <Row>
                <Col span={12} xs={24} sm={24} md={12} lg={12} xl={12}>
                    <section className='marketServed'>
                        <div className="cardMarket">
                            <img src={line} alt='line'/>
                            <h3>MERCADOS A LOS QUE ATENDEMOS</h3>
                            <p>El 40% de las pérdidas por robo de mercadería ocurren durante el traslado y el 60% de éstos están orientados a productos de los siguientes mercados:</p>
                            <Row className='rowItem'>
                                <Col flex="80px" >
                                    <img src={phone} alt='phone' width="80%"  />
                                </Col>
                                <Col className='marketItem' flex="auto">
                                    <p>
                                        Electrónicos y tecnológicos
                                    </p>
                                </Col>
                            </Row>
                            <Row className='rowItem'>
                                <Col flex="80px">
                                    <img src={antenna} alt='antenna' width="90%" className='imgAntenna'/>
                                </Col>
                                <Col className='marketItem' flex="auto" >
                                    <p>
                                        Equipos de comunicación
                                    </p>
                                </Col>
                            </Row>
                            <Row className='rowItem'>
                                <Col flex="80px" >
                                    <img src={play} alt='play' width="105%" className='imgPlay' />
                                </Col>
                                <Col className='marketItem' flex="auto">
                                    <p>
                                        Cómputo y videojuegos
                                    </p>
                                </Col>
                            </Row>
                            <Row className='rowItem'>
                                <Col flex="80px">
                                    <img src={medicCase} alt='medicCase' width="90%" />
                                </Col>
                                <Col className='marketItem' flex="auto">
                                    <p>
                                        Productos médicos
                                    </p>
                                </Col>
                            </Row>
                            <Row className='rowItem'>
                                <Col flex="80px">
                                    <img src={quimestry} alt='quimestry' width="90%" />
                                </Col>
                                <Col className='marketItem' flex="auto">
                                    <p>
                                        Químicos y productos industriales
                                    </p>
                                </Col>
                            </Row>    
                        </div>
                    </section>
                </Col>
                <Col span={12} xs={24} sm={24} md={12} lg={12} xl={12}>
                    <section className='imgThief'>
                        <div className='imgDiv'></div>
                        {/* <img src={thief} alt='item2'/> */}
                        {/* <Image
                            height='100%'
                            preview={false}
                            src={thief}
                            placeholder={
                                <Skeleton.Image style={{with:'300px'}}/>
                            }
                        /> */}
                    </section>
                </Col>
            </Row>
        </React.Fragment>
    );
}