import {useState } from 'react';
import { Menu  } from 'antd';
import { Link} from 'react-router-dom';

export default function NavButtons(props) {

    const [current, useCurrent] = useState('');

    return(
        <Menu className='MenuArmadillo' selectedKeys={[current]} mode={props.modo} theme={props.theme}>
            <Menu.Item  key="inicio" >
                <Link to={ {pathname: "home" } }>
                    INICIO
                </Link>
            </Menu.Item>
            <Menu.Item key="servicios">
                <Link to={ {pathname: "services" } }>
                    SERVICIOS
                </Link>
            </Menu.Item>
            <Menu.Item key="nosotros">
                <Link to={ {pathname: "aboutUs" } }>
                    NOSOTROS
                </Link>
            </Menu.Item>
            <Menu.Item key="contactenos">
                <Link to={ {pathname: "contactUs" } }>
                    CONTÁCTENOS
                </Link>
            </Menu.Item>
            <Menu.Item key="login">
                <Link to={ {pathname: "https://mundo.etarmadillo.com" } }
                    target={"_blank"}>
                    LOG IN
                </Link>
            </Menu.Item>
        </Menu>
    );
}


