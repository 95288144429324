import React from 'react';
import { Row, Col } from 'antd';
import './services.css';
import 'antd/dist/antd.css';
import '../../style/main.css';
import comAnt from '../../assets/services/BannerSuperior.jpg'
import line from '../../assets/services/slider-line.jpg';
import imgInfo from '../../assets/services/QueOfrecemos.png';
import phone from '../../assets/services/ElectronicosTecnologicos.png';
import antenna from '../../assets/services/EquiposDeComunicacion.png';
import play from '../../assets/services/ComputoVideojuegos.png';
import medicCase from '../../assets/services/ProductosMedicos.png';
import quimic from '../../assets/services/QuimicosProductosIndustriales.png';
import other from '../../assets/services/OtrosProductos.png';
import art from '../../assets/services/ObrasDeArte.png';
import makeUp from '../../assets/services/Maquillaje.png';
import clientArmadillo from '../../assets/services/RelaciónClienteArmadillo.jpg';
import item1 from '../../assets/services/MinimizamosPerdidas.png';
import item2 from '../../assets/services/MinimizamosRiesgos.png';
import item3 from '../../assets/services/ProtegemosActivos.png';
import item4 from '../../assets/services/ReducimosGastosOperativos.png';
import videoMali from '../../assets/services/VideoTransporteMALI.mp4';
import fotoMali from '../../assets/services/FotoMali.png';

export default function Services() {
    return(
        <React.Fragment>
            <div className='divTitle'>
                <img src={comAnt} alt='Combinación'/>
            </div>
            <div className='divContent'>
                <div className='divTransport' >
                    <img src={line} alt='line'/>
                    <h2>
                        SERVICIO BLINDADO DE TRANSPORTE DE MERCADERÍAS DE VALOR
                    </h2>
                    <p>
                        La creciente demanda de productos o mercaderías de alto valor junto con el aumento de la inseguridad en nuestras ciudades, han generado que los delitos contra el patrimonio como los robos durante el traslado se incremente a un ritmo de 30% cada año. Esto genera pérdidas millonarias a toda la cadena logística, y los costos operativos, año a año se tornan más altos.<br/><br/>

                        Las bandas, cada vez están más organizadas y utilizan tecnología para cometer los asaltos en menos de un minuto. Las soluciones actuales y tradicionales, en definitiva no están a medida del problema.
                    </p>
                </div>
                <div className='videoDivMali'>
                    <video poster={fotoMali} className="videoMali" controls >
                        <source src={videoMali} type="video/mp4"/>
                    </video>
                </div>
                <Row>
                    <Col span={12} xs={24} sm={24} md={24} lg={12} xl={12} >
                        <section className='divOfers'>
                            <div className="cardOfer">
                                <img src={line} alt='line'/>
                                <h2>
                                    QUÉ OFRECEMOS
                                </h2>
                                <p>
                                    Ofrecemos un servicio formal de transporte seguro para mercancías de alto valor. Con la última tecnología en seguridad, minimizamos las pérdidas de las empresas.
                                        <br/><br/>
                                    Luego de estudiar los tipos de asaltos, hemos desarrollado un sistema confiable que cubren ampliamente las necesidades actuales en el transporte.
                                </p>
                                <dl>
                                    <dt>Equipamiento</dt> 
                                        <dd>Nuestra flota se encuentra equipada con un sistema de blindaje y certificados internacionales, además de otros implementos que refuerzan la defensa de la unidad.</dd> 
                                    <dt>Tecnología</dt>
                                        <dd>Utilizamos sistemas que nos permiten un constante monitoreo en tiempo real a todas nuestras unidades y personal en ruta.</dd>
                                    <dt>Protocolos</dt> 
                                        <dd>Hemos desarrollado Protocolos de Seguridad para cada una de las situaciones. Éstos protocolos han sido desarrollados para reducir la posibilidad de colusión.</dd> 
                                </dl>
                                <ul>
                                    <li>Previo a la ruta</li>
                                    <li>Durante la ruta</li>
                                    <li>En destino</li>
                                    <li>Parada imprevista</li>
                                    <li>Situación de emergencia</li>
                                </ul>
                            </div>
                        </section>
                        <br/>
                        <br/>
                    </Col>
                    <Col span={12} xs={24} sm={24} md={24} lg={12} xl={12}>
                        <section className='divOfers2'>
                            <div className="cardOfer2">
                                <img src={imgInfo} alt='item2'/>
                            </div>
                        </section>
                    </Col>
                </Row>

                <div className='divMarket' >
                    <img src={line} alt='line'/>
                    <h2>
                        MERCADOS CON LOS QUE TRABAJAMOS
                    </h2>
                    <p>
                        Nuestros servicios abarcan el traslado de mercadería de un Almacén a Otro, de Aduanas a Almacén o alguna entrega directa para los siguientes mercados.
                    </p>
                    <br/>

                    <Row>
                        <Col span={12} xs={24} sm={24} md={12} lg={12} xl={12}>
                            <Row className='rowItems'>
                                <Col className='rowItemsCol' flex="90px" xs={6} sm={6} md={6} lg={6} xl={6}>
                                    <img src={phone} alt='phone'/>
                                </Col>
                                <Col className='nameItem' flex="auto" xs={17} sm={17} md={17} lg={18} xl={18}>
                                    <p>
                                        Electrónicos y tecnológicos 
                                    </p>
                                </Col>
                            </Row>
                            <Row className='rowItems'>
                                <Col className='rowItemsCol' flex="90px" xs={6} sm={6} md={6} lg={6} xl={6}>
                                    <img src={antenna} alt='antenna'/>
                                </Col>
                                <Col  className='nameItem' flex="auto" xs={17} sm={17} md={17} lg={18} xl={18}>
                                    <p>
                                        Equipos de comunicación
                                    </p>
                                </Col>
                            </Row>
                            <Row className='rowItems'>
                                <Col className='rowItemsCol' flex="90px" xs={6} sm={6} md={6} lg={6} xl={6}>
                                    <img src={play} alt='play'/>
                                </Col>
                                <Col className='nameItem' flex="auto" xs={17} sm={17} md={17} lg={18} xl={18}>
                                    <p>
                                        Cómputo y videojuegos
                                    </p>
                                </Col>
                            </Row>
                            <Row className='rowItems'>
                                <Col className='rowItemsCol' flex="90px" xs={6} sm={6} md={6} lg={6} xl={6}>
                                    <img src={medicCase} alt='medicCase'/>
                                </Col>
                                <Col className='nameItem' flex="auto" xs={17} sm={17} md={17} lg={18} xl={18}>
                                    <p>
                                        Productos médicos
                                    </p>
                                </Col>
                            </Row>
                        </Col>
                        <Col span={12} xs={24} sm={24} md={12} lg={12} xl={12}>
                            <Row className='rowItems'>
                                <Col className='rowItemsCol' flex="90px" xs={6} sm={6} md={6} lg={6} xl={6}>
                                    <img src={quimic} alt='quimic'/>
                                </Col>
                                <Col className='nameItem' flex="auto" xs={17} sm={17} md={17} lg={18} xl={18}>
                                    <p>
                                        Químicos y productos industriales
                                    </p>
                                </Col>
                            </Row>
                            <Row className='rowItems'>
                                <Col className='rowItemsCol' flex="90px" xs={6} sm={6} md={6} lg={6} xl={6}>
                                    <img src={art} alt='art'/>
                                </Col>
                                <Col  className='nameItem' flex="auto" xs={17} sm={17} md={17} lg={18} xl={18}>
                                    <p>
                                        Obras de arte
                                    </p>
                                </Col>
                            </Row>
                            <Row className='rowItems'>
                                <Col className='rowItemsCol' flex="90px" xs={6} sm={6} md={6} lg={6} xl={6}>
                                    <img src={makeUp} alt='makeUp'/>
                                </Col>
                                <Col className='nameItem' flex="auto" xs={17} sm={17} md={17} lg={18} xl={18}>
                                    <p>
                                        Maquillaje
                                    </p>
                                </Col>
                            </Row>
                            <Row className='rowItems' >
                                <Col className='rowItemsCol' flex="90px" xs={6} sm={6} md={6} lg={6} xl={6}>
                                    <img src={other} alt='other'/>
                                </Col>
                                <Col className='nameItem' flex="auto" xs={17} sm={17} md={17} lg={18} xl={18}>
                                    <p>
                                        Otros productos
                                    </p>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <br/>
                    <br/>
                    <Row>
                        <Col className='imgInvestor' span={12} xs={24} sm={24} md={24} lg={12} xl={12}>
                            {/* <div className='imgGodInvestor'></div> */}
                            <img src={clientArmadillo} alt='agreement'/>
                        </Col>
                        <Col className='clientsFocus' span={12} xs={24} sm={24} md={24} lg={12} xl={12}>
                            <img src={line} alt='line'/>
                            <h2>
                                ENFOQUE EN NUESTROS CLIENTES
                            </h2>
                            <Row className='rowFocus' justify="space-between" align="top">
                                <Col className='rowFocusCol' flex="90px" xs={6} sm={6} md={6} lg={6} xl={6}>
                                    <img src={item1} alt='phone' />
                                </Col>
                                <Col className='focusTitle' xs={17} sm={17} md={17} lg={18} xl={18}>
                                    <h4>
                                        Minimizamos pérdidas
                                    </h4>
                                    <p>
                                        Reducimos la probabilidad y efectividad de asaltos urbanos.
                                    </p>
                                </Col>
                            </Row>
                            <Row className='rowFocus' justify="space-between" align="top">
                                <Col className='rowFocusCol' flex="90px" xs={6} sm={6} md={6} lg={6} xl={6}>
                                    <img src={item2} alt='phone'/>
                                </Col>
                                <Col  className='focusTitle' xs={17} sm={17} md={17} lg={18} xl={18}>
                                    <h4>
                                        Minimizamos Riesgos
                                    </h4>
                                    <p>
                                    Con nuestros equipos, tecnología y protocolos especializados en el transporte de mercaderías valiosas.
                                    </p>
                                </Col>
                            </Row>
                            <Row className='rowFocus' justify="space-between" align="top">
                                <Col className='rowFocusCol' flex="90px" xs={6} sm={6} md={6} lg={6} xl={6}>
                                    <img src={item3} alt='phone'/>
                                </Col>
                                <Col className='focusTitle' xs={17} sm={17} md={17} lg={18} xl={18}>
                                    <h4>
                                        Reducimos Gastos Operativos
                                    </h4>
                                    <p>
                                        Tenemos la capacidad y seguridad de tranportar mayor cantidad de mercaderías en menos viajes.
                                    </p>
                                </Col>
                            </Row>
                            <Row className='rowFocus' justify="space-between" align="top">
                                <Col className='rowFocusCol' flex="90px" xs={6} sm={6} md={6} lg={6} xl={6}>
                                    <img src={item4} alt='phone'/>
                                </Col>
                                <Col className='focusTitle' xs={17} sm={17} md={17} lg={18} xl={18}>
                                    <h4>
                                        Protegemos activos
                                    </h4>
                                    <p>
                                        El 40% de robo de mercaderías ocurren durante los traslados.
                                    </p>
                                </Col>
                            </Row>
                        </Col>
                    </Row>

                    
                </div>

            </div>
        </React.Fragment>
    );
}