import { Form, Input, Button } from 'antd'; 
import React, { useState } from 'react';
import ReCAPTCHA from "react-google-recaptcha";
import 'antd/dist/antd.css';
import '../../style/main.css';
import './contactUs.css';
import line from '../../assets/services/slider-line.jpg';
import ContactRepo from '../serviceApi/ContactRepo';
import bannerContactUs from '../../assets/contact/BannerSuperior.jpg';


const layout = {
    labelCol: {
      span: 5,
    },
    wrapperCol: {
      span: 19,
    },
  };

  const validateMessages = {
    // eslint-disable-next-line no-template-curly-in-string
    required: '${label} es obligatorio!',
    types: {
      // eslint-disable-next-line no-template-curly-in-string
      email: '${label} no es un correo valido!',
    },
  };

export default function ContactUs() {

    const [able, setAble] = useState(true);
    const [form] = Form.useForm();
    const {sendEmail} = ContactRepo();
    const [message, setMessage] = useState("");

    const onFinish = (values) => {
        console.log(values.user);
        sendEmail(values.user).then(response => {
            setMessage(response.message);
            form.resetFields();
        }).catch(response => {
            setMessage("Ocurrió un error al enviar. Intente nuevamente.")
            console.error(response);
        });
    };

    function onChange(value) {
        value? setAble(false) : setAble(true);
        }

    return(
        <React.Fragment>
            <div className='divBanner'>
                <img src={bannerContactUs} alt='Combinación'/>
            </div>
            <div className='divContactUs'>
                <img src={line} alt='line'/>
                <h3>CONTÁCTENOS</h3>
                <p>Complete el formulario y nos comunicaremos a la brevedad.</p>
                <div className='divForm'>
                    <Form {...layout} name="nest-messages" form={form} onFinish={onFinish} validateMessages={validateMessages}>
                        <Form.Item
                            name={['user', 'name']}
                            label="Nombre"
                            rules={[
                            {
                                required: true,
                            },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            name={['user', 'lastName']}
                            label="Apellido"
                            rules={[
                            {
                                required: true,
                            },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            name={['user', 'phone']}
                            label="Teléfono"
                            rules={[
                            {
                                required: true,
                                message: 'Ingrese su número de teléfono!',
                            },
                            ]}
                        >
                            <Input
                            style={{
                                width: '35%',
                            }}
                            />
                        </Form.Item>
                        <Form.Item
                            name={['user', 'email']}
                            label="Email"
                            rules={[
                            {
                                type: 'email',
                                required: true,
                            },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            name={['user', 'busines']}
                            label="Empresa"
                            rules={[
                            {
                                required: true,
                            },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item name={['user', 'message']} label="Mensaje"
                            rules={[
                                {
                                    required: true,
                                },
                                ]}
                        >
                            <Input.TextArea style={{
                                height: '125px',
                            }} />
                        </Form.Item>
                        <Form.Item className='buttonRecaptcha' >
                            <ReCAPTCHA
                                // sitekey="6LdgVVMeAAAAAO2Vmv051FufMOCMqgOT73ZmIOHf"
                                sitekey="6LeNRLUZAAAAAN0dyM9OyLjFXDPfKbWqVPTLsMYu"
                                onChange={onChange}
                            />
                        </Form.Item>
                        <p>{message}</p>
                        <Form.Item  className='buttonForm' wrapperCol={{ span:5}}>
                            <Button  disabled={able} type="primary" htmlType="submit" size={'large'}>
                                Enviar
                            </Button>
                        </Form.Item>
                    </Form>
                </div>
            </div>
        </React.Fragment>
    );
}