// import useToken from './UseToken';
// import { useCookies } from 'react-cookie';
import axios from 'axios';

const url = 'https://dash.etarmadillo.com:8000/api';
const axinstace = axios.create({
    baseURL: url,
    timeout: 60000,
    withCredentials: true
    // headers: {'X-Custom-Header': 'foobar'}
});

export default function DashRepo() {
    // const { token, setToken } = useToken();
    // const [cookies, setCookie, removeCookie] = useCookies(['token']);
    const dFetch = ({ verb, path, data = undefined, params = undefined, headers }) => axinstace({
        method: verb,
        url: path,
        params,
        headers: headers,
        data: data
    }).catch(function (error) {
        if (error.response) {
            if (error.response.status === 401) {
                console.log("Error de envio en DashRepo")
                // removeCookie('token');
                // removeCookie('permissions');
                // setToken(undefined);
            }
            throw (error.response.data);
        } else if (error.request) {
            throw ({ message: 'Error en Solicitud.', error: error.request })
        } else {
            // Something happened in setting up the request that triggered an Error
            throw ({ message: 'Error interno.', error: error.message })
        }
    });

    return { dFetch }
}