import DashRepo from './DashRepo';
// import { useCookies } from 'react-cookie';

export default function VehicleRepo() {
    
    // const [cookies, setCookie] = useCookies(['token']);
    const { dFetch } = DashRepo();
    const sendEmail = (newEmail) => {
        console.log('antes de enviar el correo');
        return  dFetch({ verb: 'POST', path: '/common/contactEmail', data: newEmail, headers : {
            'Content-Type': 'application/json'}
        }).then(response => {
            return  response.data;
        });
    }

    return { sendEmail };
}